import React, { useEffect, useState } from "react";
import "./Products.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Loader/Loader";
import { addingProducts } from "../../actions/reducers/productReducer";
import { useGetProductsQuery } from "../../actions/api/productsApi";
import { Helmet } from 'react-helmet';

const Products = () => {
  const { products: reducerProduct, searchedProducts } = useSelector(
    (state) => state.product
  );
  const { data: apiProducts, error, isLoading } = useGetProductsQuery();
  const dispatch = useDispatch();

  if (apiProducts) {
    dispatch(addingProducts(apiProducts?.data));
  }

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const buttonType = params.get("buttonType");
  const [loading, setLoading] = useState(true);

  // Filter products based on buttonType
  const filteredProducts = reducerProduct.filter(
    (product) => product.category === buttonType
  );

  const displayedProducts = searchedProducts || filteredProducts;

  useEffect(() => {
    setLoading(true); 
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); 
    return () => clearTimeout(timer); 
  }, [buttonType, searchedProducts]);

  return (
    <>
      <Helmet>
        <title>Anime Clothes & Merch | Shop Cosplay & Apparel</title>
        <meta name="description" content="Browse our wide range of anime-inspired clothing including t-shirts, hoodies, cosplay outfits, and more. Shop now at Kanime Store!" />
        <meta name="keywords" content="anime apparel, anime t-shirts, cosplay outfits, anime hoodies, anime merchandise" />
        <link rel="canonical" href="https://www.kanime.store/products" />
      </Helmet>
      <div className="title">
        <h4>{buttonType}</h4>
      </div>
      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <div className="products-container">
          {displayedProducts.length > 0 ? (
            displayedProducts.map((product) => (
              <Link key={product.id} to={`/product/${product.id}`}>
                <div className="product-card">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="product-image"
                  />
                  <div className="product-info">
                    <h2 className="product-name">{product.name}</h2>
                    <p className="product-price">Rs.{product.price} <span className="old-price">{product.category === "Oversized-fit" ? "RS.1399" : "RS.799"}</span></p>
                    <p className="discount-label">HOLIDAY SEASON SALE!!!</p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>No products found</p>
          )}
        </div>
      )}
    </>
  );
};

export default Products;
