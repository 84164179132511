import React, { useState, useRef, useEffect } from "react";
import "./Zoom.css"; // CSS file for styles

const ImageZoom = ({ productUrl }) => {
  const [zoomStyle, setZoomStyle] = useState({});
  const [rectStyle, setRectStyle] = useState({ opacity: 0 });
  const pictureRef = useRef(null);

  const handleMouseMove = (e) => {
    const rect = pictureRef.current.getBoundingClientRect();
    const imageWidth = rect.width;
    const imageHeight = rect.height;

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const rectWidth = 200; // Width of the selector
    const rectHeight = 150; // Height of the selector

    // Constrain the selector within the image bounds
    const constrainedX = Math.max(rectWidth / 2, Math.min(x, imageWidth - rectWidth / 2));
    const constrainedY = Math.max(rectHeight / 2, Math.min(y, imageHeight - rectHeight / 2));

    // Update the rect's style
    setRectStyle({
      left: `${constrainedX}px`,
      top: `${constrainedY}px`,
      width: `${rectWidth}px`,
      height: `${rectHeight}px`,
      opacity: 1,
    });

    // Update the zoom window style
    setZoomStyle({
      backgroundImage: `url(${productUrl})`,
      backgroundPosition: `-${(constrainedX - rectWidth / 2) * 3}px -${(constrainedY - rectHeight / 2) * 3}px`,
      backgroundSize: `${imageWidth * 3}px ${imageHeight * 3}px`,
      opacity: 1,
    });
  };

  const handleMouseLeave = () => {
    setRectStyle({ opacity: 0 });
    setZoomStyle({ opacity: 0 });
  };

  return (
    <div className="zoom-container">
      <div
        className="picture"
        ref={pictureRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="rect" style={rectStyle}></div>
        <img src={productUrl} alt="Main" />
      </div>
      <div className="zoom" style={zoomStyle}></div>
    </div>
  );
};

export default ImageZoom;
