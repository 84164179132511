import { useState, useEffect } from "react";
import "./Product.css";
import { useNavigate, useParams } from "react-router-dom";
import Rating from "../../component/Rating/Rating";
import Modal from "../../component/Modal/Modal";
import Size_Dropdown from "../../component/Size_Dropdown/Size_Dropdown";
import Loader from "../../component/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateLength } from "../../actions/reducers/productReducer";
import { toast } from "react-toastify";
import { useGetProductQuery } from "../../actions/api/productsApi";
import { useAddCartProductMutation } from "../../actions/api/cartApi";
import { Helmet } from 'react-helmet';
import Zoom from "../../component/Zoom/Zoom";



const Product = () => {
  const {user}=useSelector(state=>state.user)
  const {length } = useSelector((state) => state.product);
  const { id } = useParams();
  const { data, error, isLoading } = useGetProductQuery(id);
  const [addCartProduct, { isLoading: addCartLoading, error: addCartError }] = useAddCartProductMutation();
 
  const userId=user?.data?.id
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [vibrate, setVibrate] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (data && data?.data) {
      setProduct(data?.data);
    }
  }, [data?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setVibrate((prev)=> !prev);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleToggleModal = (value) => {
    if (value === "size-chart") {
      // Check product category to decide which size chart to show
      if (product.category === "Regular-fit"){
        setProductImage("/Images/Regular Size.png"); // Image for regular fit
      } else if (product.category === "Oversized-fit") {
        setProductImage("/Images/Oversized Size.png"); // Image for oversized fit
      }
    } else {
      setProductImage(value); // For product image
    }
    setShowModal(true);
    console.log(product.category)
  };
  
  

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleAddToCart = async(e) => {
    if (!isLoggedIn) {
      toast.warning("Please login to add the products", {
        position: "top-center",
      });
      navigate("/login");
      return;
    }
    if (!selectedSize) {
      toast.error("Please select the size", {
        position: "top-center",
      });
      return;
    }
    try{
      const res=await addCartProduct({
        userId, 
        newProduct: {
          product: product._id,
          quantity,
          selectedSize,
        },
      }).unwrap(); 
      dispatch(updateLength(length+1))   
      setSelectedSize(null);
      toast.success(`${quantity} item(s) added to cart!`, {
        position: "top-center",
      });
    }catch(err){
      toast.error(`${quantity} item(s) not added to cart!`, {
        position: "top-center",
      });
      console.log(err)
    }
  };

  const handleBuyNow  = async(e) => {
    if (!isLoggedIn) {
      toast.warning("Please login to buy the product", {
        position: "top-center",
      });
      navigate("/login");
      return;
    }
    if (!selectedSize) {
      toast.error("Please select the size", {
        position: "top-center",
      });
      return;
    }
    try{
      const res=await addCartProduct({
        userId, 
        newProduct: {
          product: product._id,
          quantity,
          selectedSize,
        },
      }).unwrap(); 
      dispatch(updateLength(length+1))   
      setSelectedSize(null);
      navigate("/cart");;
    }catch(err){
      toast.error(`${quantity} item(s) not checkout to cart!`, {
        position: "top-center",
      });
      console.log(err)
    }
  };

  if (isLoading) {
    return  <div className="center-div"><Loader /></div>
     
  }

  if (error) {
    return <div>Error loading product: {error.message}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }
  

  return (
    <>
      <Helmet>
    <title>{product.name} | Anime Clothing by Kanime Store</title>
    <meta name="description" content={`Buy ${product.name}, a perfect piece for anime fans. Available in multiple sizes and designs.`} />
    <meta name="keywords" content={`buy ${product.name}, anime ${product.category}, anime clothes, cosplay ${product.category}`} />
    <link rel="canonical" href={`https://www.kanime.store/products/${product.id}`} />
  </Helmet>
    <div className="single-product-container" id="product">
      <div className="single-product-header">
        <h1 className="single-product-title">{product.name}</h1>
        <div>
          <p className="single-product-price">
            Price: {product.price} <span className="old-price">{product.category == "Oversized-fit" ? "RS.1399" : "RS.799"}</span>
          </p>
          <Rating />
        </div>
      </div>
      <div>
        <div className="single-product-content flex flex-col md:flex-row ">
          <div className="single-product-image md:w-1/2">
           <div style={{ maxWidth: '400px', margin: '0 auto' }}>
           <img
        src={product?.images[0]?.url}
        className="image-mobile-tab-only"
        alt="Product"
        onClick={() => handleToggleModal(product?.images[0]?.url)}
      />
            <div className="zoom-desktop-only">
            <Zoom productUrl={ product?.images[0]?.url }/>
            </div>
           
      
         </div>
            <Modal
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              productImage={productImage}
              productPath={productImage}
              productSizeImg={null}
            />
          </div>
          


        </div>
        <div className="single-product-details md:w-1/2">
            <p className="single-product-description">
              Price:{product.price}
            </p>
            <div className="quantity-input-container">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantity"
                className="quantity-input"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                min="1"
              />
            </div>
            <div className="scale flex items-center">
              <img src="/Images/scale.png" alt="Size Chart" />
              <p
                className="text-sm ml-1 whitespace-nowrap"
                onClick={() => handleToggleModal("size-chart")}
              >
                Size Chart
              </p>
            </div>
            <div>
              <Size_Dropdown
                handleSizeChange={handleSizeChange}
                selectedSize={selectedSize}
                productCategory={product.category}
              />
            </div>
            <div className="single-product-buttons flex flex-col md:flex-row mt-4">
              {product.stock > 0 && <button
                className={`add-to-cart-button mb-2 md:mb-0 md:mr-2 ${
                  vibrate ? "vibrate" : ""
                }`}
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>}
              {product.stock > 0 && <button className="buy-now-button" onClick={handleBuyNow}>
                Buy Now
              </button>}
              {(product.stock == 0 || product.stock < 1) && <button className="out-of-stock">
                Out of Stock
              </button>}
            </div>
            <img src="/Images/razopay.png" className="" />
          </div>

        <div className="description mt-3">
          <h1>DESCRIPTION:</h1>
          <h1>Anime Collection</h1>
          <p>
            Kanime offers finest anime collection to all the true otakus
            around the world. True piece of artworks which will satisfy your
            hunger of expressing the elements of your favourite characters
            with the essence of impeccable design masterpiece.
          </p>
          <h1>Material</h1>
          <p>-100% super combed cotton</p>
          <p>-240 GSM fabric</p>
          <p>-Bio washed material</p>
          <p>-Loop knit cotton</p>
          <p>-Double stitched wrinkle free material</p>
          <h1>Wash Care</h1>
          <p>-Wash inside out with cold water</p>
          <p>-Do not scrub on the print</p>
          <p>-Do not iron on the print</p>
          <p>-Do not bleach or dryclean</p>
          <h1>Note</h1>
          <p>- Colors might slightly differ from the picture due to light change</p>
          <p>- Size difference of 0.5 to 1 inch could be there</p>
          <h1>Return Info</h1>
          <p>- For any return related query mail us at kanimeclothing@gmail.com</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Product;
