import React from "react";
import "./Modal.css";

const Modal = ({
  handleCloseModal,
  showModal,
  productImage,
  productPath,
  productSizeImg,
}) => {
  return (
    <>
      <div className={`modal ${showModal ? "show" : ""}`}>
  <div className="modal-content">
    <span className="close-button" onClick={handleCloseModal}>
      &times;
    </span>
    <img src={productImage} alt="Size Chart" />
  </div>
</div>
    </>
  );
};

export default Modal;
