import React, { useEffect, useState } from "react";
import "../HomePage/HomePage.css";
import Sidebar from "../../component/SideBar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../component/Dropdown/Dropdown";
import "flowbite";

import { useDispatch, useSelector } from "react-redux";
import { searching ,updateLength} from "../../actions/reducers/productReducer";
import "./Header.css";
import { Helmet } from "react-helmet";

const Header = () => {

  const [searchProducts, setSearchProducts] = useState("");
  const dispatch=useDispatch()
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const { length,products } = useSelector((state) => state.product);
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  
 
  const [loading, setLoading] = useState(false);

  // dispatch(updateCart())

 
  const handleClick = () => {
    setLoading(true);
    // Simulate an API call or any asynchronous action
    setTimeout(() => {
      setLoading(false);
      // Further actions after loading
    }, 2000); // Example: 2 seconds delay to simulate loading
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const searchHandler = (event) => {
    event.preventDefault();
    const filteredProducts = products.filter((item) =>
      item.name.toLowerCase().includes(searchProducts.toLowerCase())
    );
    dispatch(searching(filteredProducts));
    navigate("/searchedProducts");
    setSearchedProducts([]);
    if(isOpen){
      setIsOpen(false)
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchProducts(query);
    if (query) {
      const filteredProducts = products.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchedProducts(filteredProducts);
    } else {
      setSearchedProducts([]);
    }
  };

  const handleProductClick = (name) => {
    const filteredProducts = products.filter((item) =>
      item.name.toLowerCase().includes(name.toLowerCase())
    );
    dispatch(searching(filteredProducts));
    setSearchProducts(name);
    setSearchedProducts([]);
    navigate("/searchedProducts");
    if(isOpen){
      setIsOpen(false)
    }
  };
  
  return (
    <div>
       <Helmet>
        <title>Kanime Store | Anime Clothing & Merchandise</title>
        <meta
          name="description"
          content="Explore anime-themed t-shirts, hoodies, and merchandise at Kanime Store. Search for your favorite anime-inspired clothing and accessories."
        />
        <meta name="keywords" content="anime clothing, search anime products, anime t-shirts, anime hoodies, anime merchandise, Kanime Store" />
        <link rel="canonical" href="https://www.kanime.store/" />
      </Helmet>
      <div className="offer-container">
        {/* <div className="offer"><p>BUY 3 REGULAR T-SHIRTS @ ONLY 999/-</p> <p>BUY 3 OVERSIZED T-SHIRTS @ ONLY 1899/-</p><p>BUY 3 REGULAR T-SHIRTS @ ONLY 999/-</p> <p>BUY 3 OVERSIZED T-SHIRTS @ ONLY 1899/-</p></div> */}
        <div className="offer"> <p>PREORDER SHIPPING DATE WILL BE ANNOUNCED LATER!!!</p> <p>PREORDER SHIPPING DATE WILL BE ANNOUNCED LATER!!!</p> <p>PREORDER SHIPPING DATE WILL BE ANNOUNCED LATER!!!</p> <p>PREORDER SHIPPING DATE WILL BE ANNOUNCED LATER!!!</p> </div>
      </div>
      <div className="header">
        <Link to="/">
          <img src="/Images/Kanime_Logo.png" className="header_logo" />
        </Link>
        <Dropdown setSearchProducts={setSearchProducts} />
        <div className="icon_align">
          <div className="mt-6 search">
            <form onSubmit={searchHandler}>
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                value={searchProducts}
                onChange={handleInputChange}
              />
            </form>
            {searchProducts && searchedProducts.length > 0 && (
              <ul className="search-results">
                {searchedProducts.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleProductClick(item.name)}
                  >
                    {item.name.charAt(0).toUpperCase() +
                      item.name.slice(1).toLowerCase()}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="login_cart">
            <Link to={isLoggedIn ? "/userAccount" : "/login"}>
              <i className="fa-solid fa-user fa-2x"></i>
            </Link>
            <Link to="/cart" className="cart-icon">
              <i className="fa-solid fa-cart-shopping fa-2x ml-5" onClick={handleClick}></i>
              <div className="cart-count">{length}</div>
            </Link>
          </div>
          <div className="mt-1 menu">
            <i onClick={toggleDrawer} className="fas fa-bars fa-2x"></i>
          </div>
        </div>
      </div>
      <Sidebar
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        searchProducts={searchProducts}
        handleProductClick={handleProductClick}
        searchedProducts={searchedProducts}
        handleInputChange={handleInputChange}
        searchHandler={searchHandler}
        isLoggedIn={isLoggedIn}
      />
    </div>
  );
};

export default Header;
